import { Component, ElementRef, ViewChild } from '@angular/core';
import { Api } from './services/api';
import { IInventoryItem, IPurchase, ISale } from '../../../../../deno/interfaces/items';
import { Guid } from 'src/helpers/utility';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

const TRADERA_FEE_PERCENT = 0.1;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  purchases?: (IPurchase & any)[];
  sales?: ISale[];
  inventory?: IInventoryItem[];

  newPurchase?: IPurchase;
  newPurchaseItem?: IInventoryItem;

  newSale?: ISale;

  availableTags?: string[];

  quickTags: string[] = ['lindex', 'hm', 'pop', 'newbie', 'nameit'];


  stats = {
    purchasedProducts: 0,
    soldProducts: 0,
    purchaseTotal: 0,
    saleTotal: 0,
    inventoryCount: 0,
    inventoryTotal: 0,
    resultTotal: 0,
    soldItems: <IInventoryItem[]>[]
  }


  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  @ViewChild('tagInput') tagInput?: ElementRef<HTMLInputElement>;
  @ViewChild('newPurchaseItemNameInput') newPurchaseItemNameInput?: ElementRef<HTMLInputElement>;

  inventorySearch?: string = '';
  inventorySearchTags: string[] = [];

  deductTraderaFee = true;
  constructor(private api: Api) {
    this.filteredTags = this.tagsCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.availableTags!.slice())),
    );
  }

  async ngOnInit() {
    this.loadNewPurchaseCache();

    this.newSale = {
      date: new Date(),
      items: [],
      total: 0,
      identifier: Guid()
    };

    this.newPurchaseItem = {
      identifier: Guid(),
      tags: [],
      name: '',
      purchasePrice: 0
    };

    this.purchases = await this.api.public().get<IPurchase[]>('purchases');
    await this.fetchInventory();
    this.sales = await this.api.public().get<ISale[]>('sales');

    this.availableTags = await this.api.public().get<string[]>('available-tags');
    this.refreshStats();
  }

  async fetchInventory(search?: string) {
    this.inventory = await this.api.public().get<IInventoryItem[]>(`inventory?search=${search ? search : ''}&tags=${this.inventorySearchTags.join(',')}`);


  }

  async addPurchase() {

    for (let item of this.newPurchase!.items) {
      item.purchasePrice = this.newPurchase!.total / this.newPurchase!.items.length;
    }

    let purchase = this.newPurchase;
    this.deleteNewPurchaseCache();
    this.loadNewPurchaseCache();

    await this.api.public().post('purchase', purchase);
    this.purchases = await this.api.public().get<IPurchase[]>('purchases');
    this.inventory = await this.api.public().get<IInventoryItem[]>('inventory');
    this.refreshStats();
  }

  async addPurchaseItem() {

    for (let tag of this.newPurchaseItem?.tags!) {
      if (!this.availableTags!.includes(tag)) {
        this.availableTags!.push(tag);
      }
    }
    this.newPurchase?.items.push(this.newPurchaseItem!);
    this.newPurchaseItem = {
      identifier: Guid(),
      tags: [],
      name: '',
      purchasePrice: 0
    };

    this.saveNewPurchaseCache();
  }

  isAddedToNewSale(item: IInventoryItem) {
    return this.newSale!.items!.some(i => i.identifier == item.identifier)
  }

  addSaleItem(item: IInventoryItem) {
    this.newSale?.items.push(item);
    this.inventory?.splice(this.inventory?.indexOf(item), 1);
  }

  async addSale() {
    if (this.deductTraderaFee) {
      let fee = this.newSale!.total * TRADERA_FEE_PERCENT;
      this.newSale!.total -= fee;
    }


    for (let item of this.newSale!.items) {
      item.salePrice = this.newSale!.total / this.newSale!.items.length;
    }

    let sale = this.newSale;
    this.newSale = {
      date: new Date(),
      items: [],
      total: 0,
      identifier: Guid()
    };

    await this.api.public().post('sale', sale);
    this.sales = await this.api.public().get<ISale[]>('sales');
    this.inventory = await this.api.public().get<IInventoryItem[]>('inventory');
    this.refreshStats();
  }

  refreshStats() {
    this.stats.purchasedProducts = this.getPurchasedProducts();
    this.stats.soldProducts = this.getSoldProducts();
    this.stats.purchaseTotal = this.getPurchaseTotal();
    this.stats.saleTotal = this.getSaleTotal();
    this.stats.inventoryCount = this.getInventoryCount();
    this.stats.inventoryTotal = this.getInventoryTotal();
    this.stats.resultTotal = this.getResultTotal();
    this.stats.soldItems = this.getSoldItems();
  }


  getPurchasedProducts() {
    return this.purchases!.reduce((sum, current) => sum + current.items.length, 0);
  }

  getSoldProducts() {
    return this.sales!.reduce((sum, current) => sum + current.items.length, 0);
  }

  getPurchaseTotal() {
    return this.purchases!.reduce((sum, current) => sum + current.total, 0);
  }

  getSaleTotal() {
    return this.sales!.reduce((sum, current) => sum + current.total, 0);
  }

  getInventoryCount() {
    return this.inventory!.length;
  }

  getInventoryTotal() {
    return this.inventory!.reduce((sum, current) => sum + current.purchasePrice, 0);
  }

  getResultTotal() {
    return this.getSaleTotal() - (this.getPurchaseTotal() - this.getInventoryTotal());
  }

  getSoldItems() {
    let result = [];
    for (let sale of this.sales!) {
      for (let item of sale.items) {
        result.push(item);
      }
    }
    return result;
  }



  numberFormat(value: number, keepDecimals?: boolean) {
    let split = (value: string) => {
      let split = value.split(',');
      if (split.length == 3)
        return `${split[0]} ${split[1]}`;
      if (split.length == 4)
        return `${split[0]} ${split[1]} ${split[2]}`;
      if (split.length == 5)
        return `${split[0]} ${split[1]} ${split[2]} ${split[3]}`;
      else
        return split[0];
    }

    if (Number.isNaN(value))
      return '-';

    let formattedValue = value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      .replace(',', ' ')
      .replace(',', ' ')
      .replace(',', ' ')
      .replace(',', ' ')
      .replace(',', ' ')
      .replace('.', ',');

    if (!keepDecimals) {
      if (formattedValue.endsWith(',00'))
        formattedValue = formattedValue.split(',')[0];
    }

    return formattedValue;
    //return split(formattedValue);
  }


  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();


    if (value) {
      this.newPurchaseItem!.tags!.push(value.toLowerCase());
    }

    // Clear the input value
    event.chipInput!.clear();

    this.tagsCtrl.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.newPurchaseItem!.tags!.indexOf(tag);

    if (index >= 0) {
      this.newPurchaseItem!.tags!.splice(index, 1);
    }
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.newPurchaseItem!.tags!.push(event.option.viewValue);
    this.tagInput!.nativeElement.value = '';
    this.tagsCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.availableTags!.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  addQuickTag(tag: string) {
    this.newPurchaseItem?.tags?.push(tag);

    let text = tag.charAt(0).toUpperCase() + tag.slice(1);
    this.newPurchaseItem!.name += `${text}, `;
    setTimeout(() => {
      this.newPurchaseItemNameInput?.nativeElement.focus();

      this.newPurchaseItemNameInput?.nativeElement.setSelectionRange(this.newPurchaseItem!.name.length, this.newPurchaseItem!.name.length);
    }, 100);
  }


  saveNewPurchaseCache() {
    localStorage.setItem('newPurchase', JSON.stringify(this.newPurchase));
  }

  loadNewPurchaseCache() {
    let newPurchase = localStorage.getItem('newPurchase');
    if (newPurchase) {
      this.newPurchase = JSON.parse(newPurchase);
    }
    else {
      this.newPurchase = {
        date: new Date(),
        items: [],
        total: 0,
        identifier: Guid()
      };
    }
  }

  deleteNewPurchaseCache() {
    localStorage.removeItem('newPurchase');
  }


  async fetchPurchaseDetails(purchase : (IPurchase & any)){
    let response : any = await this.api.public().get<IPurchase>(`purchase/${purchase.identifier}/details`);
    purchase.totalSold = response.totalSold;
    purchase.revenue = purchase.totalSold - purchase.total;
    for(let identifier in response.items){
      let item = response.items[identifier];
      let purchaseItem = purchase.items.find((x : any) => x.identifier == identifier);
      if(purchaseItem){
        purchaseItem.salePrice = item.salePrice;
        purchaseItem.revenue = item.revenue;
      }
    }


  }




}
