<div>
  <mat-tab-group *ngIf="purchases && sales && inventory">
    <mat-tab label="Inköp" #purchasesTab>
      <br />
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="fullwidth">
            <input matInput [matDatepicker]="purchaseDatePicker" placeholder="Inköpsdatum"
              [(ngModel)]="newPurchase!.date">
            <mat-datepicker-toggle matSuffix [for]="purchaseDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #purchaseDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="fullwidth">
            <input matInput type="number" [(ngModel)]="newPurchase!.total" placeholder="Pris">
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <h3>Kläder</h3>
          <table class="fullwidth styled">
            <tr>
              <td>
                <mat-form-field class="fullwidth">
                  <input matInput #newPurchaseItemNameInput [(ngModel)]="newPurchaseItem!.name"
                    placeholder="Ange plagg">
                </mat-form-field>
                <mat-form-field class="fullwidth" *ngIf="availableTags">
                  <mat-label>Taggar</mat-label>
                  <mat-chip-list #chipList aria-label="Taggar">
                    <mat-chip *ngFor="let tag of newPurchaseItem!.tags" (removed)="removeTag(tag)">
                      {{tag}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input placeholder="Ny tagg.." #tagInput [formControl]="tagsCtrl" [matAutocomplete]="auto"
                      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addTag($event)">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
                    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                      {{tag}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td class="align-right">
                <div class="quick-tags">
                  <button class="styled outline" *ngFor="let tag of quickTags"
                    (click)="addQuickTag(tag)">{{tag}}</button>
                </div>
                <button class="styled" [disabled]="!newPurchaseItem!.name || !newPurchaseItem!.name.length"
                  (click)="addPurchaseItem()">Lägg till</button>
              </td>
            </tr>
            <tr *ngFor="let item of newPurchase!.items">
              <td colspan="2">
                <mat-form-field class="fullwidth">
                  <input matInput [(ngModel)]="item.name" (blur)="saveNewPurchaseCache()">
                </mat-form-field>
              </td>
            </tr>
          </table>

        </div>
        <div class="col-sm-12">
          <br />
          <button class="styled fullwidth" [disabled]="!newPurchase!.items!.length" (click)="addPurchase()">Registrera
            köp</button>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div class="row">
        <h2>Tidigare inköp</h2>
        <table class="styled fullwidth">
          <tr *ngFor="let purchase of purchases">
            <td>
              {{purchase.date | date:'yyyy-MM-dd'}}
            </td>
            <td>
              <table class="styled fullwidth">
              <ng-container *ngFor="let item of purchase.items; let i = index">
                  <tr>
                    <td>
                      <span>{{item.name}}</span>
                    </td>
                    <td class="align-right">
                      <div *ngIf="item.revenue">
                        <span>
                          <mat-icon class="small-icon">shopping_cart</mat-icon>
                          {{numberFormat(item.purchasePrice, false)}} kr
                          <br/>
                          <mat-icon class="small-icon">point_of_sale</mat-icon>
                           {{numberFormat(item.salePrice, false)}}
                          kr
                        </span>
                        <br/>
                        <span [ngClass]="{'positive': item.revenue > 0, 'negative': item.revenue < 0}">({{numberFormat(item.revenue, false)}} kr)</span>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </td>
            <td class="align-right">
              <div *ngIf="!purchase.totalSold">
                {{numberFormat(purchase.total, true)}} kr <mat-icon class="small-icon clickable"
                  (click)="fetchPurchaseDetails(purchase)">monetization_on</mat-icon>
              </div>
              <div *ngIf="purchase.totalSold">
                <mat-icon class="small-icon">shopping_cart</mat-icon>
                {{numberFormat(purchase.total, true)}} kr
                <br />
                <mat-icon class="small-icon">point_of_sale</mat-icon>
                {{numberFormat(purchase.totalSold, true)}} kr
                <br />
                <span
                  [ngClass]="{'positive': purchase.revenue > 0, 'negative': purchase.revenue < 0}">({{numberFormat(purchase.revenue,
                  true)}} kr)</span>

              </div>



            </td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Försäljning" #salesTab>
      <br />
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="fullwidth">
            <input matInput [matDatepicker]="salesDatePicker" placeholder="Försäljningsdatum"
              [(ngModel)]="newSale!.date">
            <mat-datepicker-toggle matSuffix [for]="salesDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #salesDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="fullwidth">
            <input matInput type="number" [(ngModel)]="newSale!.total" placeholder="Pris">
          </mat-form-field>
          <br />
          <mat-checkbox [(ngModel)]="deductTraderaFee">Dra av Tradera-avgift</mat-checkbox>
        </div>
        <div class="col-sm-12" *ngIf="newSale!.items.length">
          <h3>Sålda kläder</h3>
          <table class="fullwidth styled">
            <tr *ngFor="let item of newSale!.items">
              <td>{{item.name}}</td>
            </tr>
          </table>
          <br />
          <br />
        </div>

        <div class="col-sm-12">
          <br />
          <button class="styled fullwidth" [disabled]="!newSale!.items!.length" (click)="addSale()">Registrera
            försäljning</button>
          <br />
        </div>

        <div class="col-sm-12" *ngIf="inventory">
          <div class="row">
            <div class="col-sm-12">
              <h3>Tillgängliga kläder</h3>
            </div>
            <div class="col-sm-6 align-right">
              <mat-form-field class="fullwidth">
                <label>Sök</label>
                <input matInput [(ngModel)]="inventorySearch" (keyup)="fetchInventory(inventorySearch)">
              </mat-form-field>
            </div>
            <div class="col-sm-6 align-right">
              <mat-form-field class="fullwidth" *ngIf="availableTags">
                <label>Taggar</label>
                <mat-select multiple (selectionChange)="fetchInventory(inventorySearch)"
                  [(ngModel)]="inventorySearchTags">
                  <mat-option *ngFor="let tag of availableTags" [value]="tag">
                    {{tag}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <table class="fullwidth styled">
            <ng-container *ngFor="let item of inventory">
              <tr *ngIf="!isAddedToNewSale(item)">
                <td>{{item.name}} ({{numberFormat(item.purchasePrice, true)}} kr)</td>
                <td class="align-right">
                  <button class="styled" (click)="addSaleItem(item)">Lägg till</button>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="!inventory.length">
              Inga produkter
            </ng-container>
          </table>

        </div>

      </div>
      <br />
      <hr />
      <br />
      <div class="row">
        <h2>Tidigare försäljningar</h2>
        <table class="styled fullwidth">
          <tr *ngFor="let sale of sales">
            <td>
              {{sale.date | date:'yyyy-MM-dd'}}
            </td>
            <td>
              <ng-container *ngFor="let item of sale.items; let i = index">
                <div>
                  <span>{{item.name}}</span>
                </div>
              </ng-container>
            </td>
            <td class="align-right">
              {{numberFormat(sale.total, true)}} kr
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Resultat" #resultTab>
      <br />
      <div class="row result" *ngIf="purchases && sales && inventory">
        <div class="col-xs-6 align-center">
          <h2>Köpta plagg</h2>
          <h1>{{numberFormat(stats.purchasedProducts)}}</h1>
        </div>
        <div class="col-xs-6 align-center">
          <h2>Sålda plagg</h2>
          <h1>{{numberFormat(stats.soldProducts)}}</h1>
        </div>
        <div class="col-xs-6 align-center">
          <h2>Inköp</h2>
          <h1>{{numberFormat(stats.purchaseTotal)}} kr</h1>
        </div>
        <div class="col-xs-6 align-center">
          <h2>Försäljning</h2>
          <h1>{{numberFormat(stats.saleTotal)}} kr</h1>
        </div>
        <div class="col-xs-6 align-center">
          <h2>Osålda plagg</h2>
          <h1>{{numberFormat(stats.inventoryCount)}}</h1>
        </div>
        <div class="col-xs-6 align-center">
          <h2>Osålt värde</h2>
          <h1>{{numberFormat(stats.inventoryTotal)}} kr</h1>
        </div>
        <div class="col-xs-12 align-center"
          [ngClass]="{'positive': stats.resultTotal > 0, 'negative': stats.resultTotal < 0}">
          <h2>Resultat</h2>
          <h1>{{numberFormat(stats.resultTotal)}} kr</h1>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <table class="styled fullwidth">
        <tr>
          <th>Plagg</th>
          <th class="align-right">Inköp</th>
          <th class="align-right">Försäljning</th>
          <th class="align-right">Resultat</th>
        </tr>
        <tr *ngFor="let item of stats.soldItems">
          <td>{{item.name}}</td>
          <td class="align-right">{{numberFormat(item.purchasePrice, true)}} kr</td>
          <td class="align-right">
            {{numberFormat(item.salePrice!, true)}} kr
          </td>
          <td class="align-right"
            [ngClass]="{'positive': item.salePrice! > item.purchasePrice, 'negative': item.salePrice! < item.purchasePrice}">
            {{numberFormat(item.salePrice! - item.purchasePrice, true)}} kr
          </td>
        </tr>
      </table>
    </mat-tab>
  </mat-tab-group>
</div>
